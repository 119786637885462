import { createAjax } from "../utils/tool";

/* update avater or cover */
export const updateImage = (target, boundary, data) => {
  return $.ajax({
    url: target,
    method: "PATCH",
    contentType: `multipart/form-data; boundary=${boundary}`,
    processData: false,
    data,
  });
};

/* inner reference  */
export const getPeriodicals = (page = 1, data = {}) => {
  return createAjax({
    url: "/api/v1/periodicals",
    data: {
      ...data,
      page,
    },
  });
};

export const getInnerRerencesByPeriodical = id => {
  return createAjax({
    url: `/api/v1/periodicals/${id}/item`,
  });
};

export const getInnerRerencesByCategory = (categoryName, page) => {
  return createAjax({
    url: "/api/v1/periodicals/inner_reference",
    data: {
      page,
      tag_name: categoryName,
    },
  });
};

/* -----inner reference---- */

export const getTargetInfo = target => {
  return createAjax({ url: target });
};

export const switchLike = (target, isLike) => {
  return createAjax({
    url: `${target}/${isLike ? "dislike" : "like"}`,
    method: "POST",
  });
};

export const deleteTarget = target => {
  return createAjax({
    url: target,
    method: "DELETE",
  });
};

export const getSearchResultByKeyword = keyword => {
  return createAjax({
    url: "/search",
    method: "POST",
    data: { keyword },
  });
};

/* Notification */
export const readNotification = targetId => {
  return createAjax({
    url: `/notifications/${targetId}/read`,
    method: "PATCH",
  });
};

export const readAllNotifications = () => {
  return createAjax({
    url: "/notifications/read_all",
    method: "PATCH",
  });
};

export const getReplyNotifications = () => {
  return createAjax({
    url: "/notifications?notify_type=reply",
  });
};

/* Comments */
export const getTargetComments = target => {
  return createAjax({
    url: `${target}/comments`,
  });
};

export const getCommentReplies = commentId => {
  return createAjax({
    Accept: "text/html-partial",
    dataType: "html",
    url: `/comments/${commentId}`,
  });
};

export const createComment = (url, content) => {
  return createAjax({
    Accept: "text/html-partial",
    dataType: "html",
    url,
    method: "POST",
    data: {
      "comment[content]": content,
    },
  });
};

/* Forget password */
export const getVerificationCodeByName = (name, rucaptcha) => {
  return createAjax({
    url: "/send_verification_code",
    method: "POST",
    data: {
      login_name: name,
      _rucaptcha: rucaptcha,
    },
  });
};

export const sendVerifyCodeWithName = (name, code) => {
  return createAjax({
    url: "/sudo",
    method: "POST",
    data: {
      login_name: name,
      code,
    },
  });
};

export const resetPassword = (name, password) => {
  return createAjax({
    url: "/password_reset",
    method: "POST",
    data: {
      login_name: name,
      password,
    },
  });
};

/* User */
export const updateUserInfo = (name, bio) => {
  return createAjax({
    url: "/settings/profile",
    method: "PATCH",
    data: {
      "user[name]": name,
      "user[bio]": bio,
    },
  });
};

export const getUserInfo = () => {
  return createAjax({
    url: "/setting/information",
  });
};

export const sudo = code => {
  return createAjax({
    url: "/sudo",
    method: "POST",
    data: {
      code,
    },
  });
};

export const tryModifyEmailOrMobile = type => {
  return createAjax({
    url: `/settings/${type}/edit`,
  });
};

export const verifyEmail = () => {
  return createAjax({
    url: "/settings/verify_email",
    method: "POST",
  });
};

export const sendVerifyCodeByName = name => {
  return createAjax({
    url: "/send_verification_code",
    method: "POST",
    data: {
      login_name: name,
    },
  });
};

export const sendPhoneVerifyCode = mobile => {
  return createAjax({
    url: "/phone_verify_code",
    method: "POST",
    data: {
      mobile,
    },
  });
};

export const cancelBind = type => {
  return createAjax({
    url: `/settings/authorizations/${type}`,
    method: "DELETE",
  });
};

export const settingEmail = newEmail => {
  return createAjax({
    url: "/settings/email",
    method: "PATCH",
    data: {
      "user[email]": newEmail,
    },
  });
};

export const settingPassword = (password, originPassword) => {
  return createAjax({
    url: "/settings/password",
    method: "PATCH",
    data: {
      "user[password]": password,
      "user[origin_password]": originPassword,
    },
  });
};
/* setting end */

/* read later */
export const readInReadLater = id => {
  return createAjax({
    url: `/api/v1/read_laters/${id}/read`,
    method: "POST",
  });
};

export const updateReadLaterProgress = (articleId, progress) => {
  return createAjax({
    url: "/api/v1/read_laters",
    data: {
      read_later: {
        content_id: articleId,
        content_type: "Article",
        progress,
      },
    },
    isAsync: false,
    method: "POST",
  });
};

/* industry */
export const getFilterInIndustry = (type, keyword) => {
  return createAjax({
    url: `/graph/${type}/filter?keyword=${keyword}`,
  });
};

export const getListInIndustry = (type, checkedTagObj, keywords, page) => {
  return createAjax({
    url: `/graph/${type}`,
    data: {
      ...checkedTagObj,
      keyword: keywords,
      page,
    },
  });
};

/* industry end */

export const searchBase = keywords => {
  return createAjax({
    url: `/api/v1/search/base?keywords=${encodeURI(keywords)}`,
  });
};

export const submitInsightForm = data => {
  return createAjax({
    url: "/insight/authenticate",
    method: "POST",
    data,
  });
};

export const getInsightData = () => {
  return createAjax({
    url: "/insight/apply",
  });
};

export const getDocumentFile = id => {
  return createAjax({
    url: `/documents/${id}/send_email`,
  });
};
